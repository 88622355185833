<template>
  <div id="vacationCarryOverRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit vacation carry over request entry' : isEditable ? 'vacation carry over request entry': 'view vacation carry over request entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'" :closeModalEvent="closeViewModalEvent">
          <div>
            <transition name="slide-fade">
              <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
            </transition>
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <EmployeeInfoDisplay v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :request="request" :vacationMaster="vacationMaster" :additionalData="additionalData"></EmployeeInfoDisplay>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="requestCanBeRaisedForEmployee && !showGenerateVacationButton">
      <div class="col">
        <form id="entryForm" onsubmit="event.preventDefault();" novalidate>
          <fieldset v-bind:disabled="isSavingRequest">
            <VacationCarryOverRequestEntryForm :employeeInfo="employeeInfo" :request="request" :vacationMaster="previousVacationMaster" :additionalData="additionalData" />
            <div class="m-2" v-if="request.id && isEditable">
              <h4>review comment</h4>
              <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required v-bind(requestCanBeRaisedForEmployee || (!isEditable && request.id)):disabled="disableFields"></textarea>
            </div>
            <div class="form-group col-md-12 mt-4 text-center" v-if="isEditable">
              <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
              <!--<span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
                    <title>DOA and Back-To-Back is automatically applied</title>
                  </path>
                </svg>
              </span>-->
            </div>
          </fieldset>
        </form>
        <div class="text-center mt-4" v-if="isEditable">
          <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSavingRequest">Cancel</button>
          <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
        <div class="text-center mt-4" v-else-if="isReviewPage">
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canRejectRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canApproveRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
        </div>
      </div>
    </div>
    <div v-if="requestCanBeRaisedForEmployee && showGenerateVacationButton" class="text-center">
      <button class="btn btn-primary d-inline mr-2" @click="generateVacationData" v-bind:disabled="isGeneratingVacationData">Generate vacation data</button>
      <span v-if="showGenerateCashInLieuRequestButton">
        Or <router-link :to="{ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employeeInfo.employeeId || currentUser.employeeId }}">request cash in-lieu</router-link>-->
      </span>      <!--Or <router-link :to="'/requests/vacation/cashinlieu/?employeeId='+ employeeInfo.employeeId">request cash in-lieu</router-link>-->
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>

  import store from '@/store'
  import { mapState } from 'vuex'

  import { FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import ErrorMessages from '@/utils/errors/messages'
  import Enums from '@/utils/enums'

  import { employeeService, vacationMasterService, leaveRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  import EmployeeInfoDisplay from '@/components/vacationCarryOver/EmployeeInfoDisplay'
  import VacationCarryOverRequestEntryForm from '@/components/vacationCarryOver/EntryForm'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'
  import VacationMasterMixin from '@/mixins/leaveRequest/vacationMaster'

  export default {
    name: 'VacationCarryOverRequestView',
    components: {
      EmployeeInfoDisplay,
      VacationCarryOverRequestEntryForm,
      RequestActionList
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin, VacationMasterMixin],
    data: () => ({
      request: {
        id: null,
        vacationYear: null,
        numberOfDays: null,
        reasonForChange: '',
        intendedApproverId: '',
        intendedApproverName: '',
        requestType: Enums.RequestType.VacationDaysCarryOver.value,
        requestActions: []
      },
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        carryOverRequests: [],
        version: null
      },
      previousVacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        carryOverRequests: [],
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isFieldEmployee: false,
        isResidentExpatriateEmployee: false,
        isShiftEmployee: false,
        isOnForeignService: false
      },
      additionalData: {
        vacationYears: [],
      },
      comment: null,
      showGenerateVacationButton: false,
      showGenerateCashInLieuRequestButton: false,
      isGeneratingVacationData: false,
      closeViewModalEvent: 'CLOSE_VIEW_MODAL'
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee || employee.isExpatriateEmployee)
        }
        return []
      },
      requestCanBeRaisedForEmployee() {
        if (this.isEditable && this.employeeInfo.employeeId) {
          //if (this.isEditPage && !this.canEditRequest(this.request)) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
          //  return false
          //}
          if (this.employeeInfo.isFieldEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.FieldEmployeesNotAllowed, this.request.requestType)
            return false
          }
          var currentYear = new Date().getFullYear()
          var currentMonth = new Date().getMonth() + 1
          if (this.employeeInfo.isRegularEmployee && currentYear == this.request.vacationYear && currentMonth > this.appSettings.vacation.lastMonthForVacationCarryOver) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.LastMonthForVacationCarryOverElapsed, this.request.requestType)
            return false
          }
          //if (this.employeeInfo.isExpatriateEmployee && new Date(this.employeeInfo.sbuStartDate).getFullYear() == this.request.vacationYear) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.LastMonthForVacationCarryOverElapsed, this.request.requestType)
          //}
          if (this.vacationMaster.id && this.vacationMaster.carriedOverDays) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CarryOverDaysAlreadyExist, this.request.requestType)
            return false
          }
          if (this.vacationMaster.id && this.vacationMaster.carryOverRequests.length) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CarryOverRequestAlreadyExist, this.request.requestType)
            return false
          }
          if (this.vacationMaster.id && !this.previousVacationMaster.id) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoVacationRecordForPreviousYear, this.request.requestType)
            return false
          }
          if (this.vacationMaster.id && !this.previousVacationMaster.unScheduledVacationDays) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUnscheduledVacationDays, this.request.requestType)
            return false
          }
          /*if(this.vacationMaster)*/
          //if (currentYear == request.vacationYear - 1 && currentMonth < appSettings.vacation.FirstMonthForVacationCarryOver) {

          //}
          if (!this.vacationMaster.id && !this.showGenerateVacationButton) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return false;
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    watch: {
      'request.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.resetVacationMasterData()
            this.fetchEmployeeData(this.employeeInfo)
          }
        }
      }
    },
    methods: {
      resetVacationMasterData() {
        this.vacationMaster.id = null
        this.vacationMaster.vacationYear = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unScheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.version = null
        this.vacationMaster.carryOverRequests.splice(0, this.vacationMaster.carryOverRequests.length)
        this.previousVacationMaster.id = null
        this.previousVacationMaster.vacationYear = null
        this.previousVacationMaster.vacationDaysDue = 0
        this.previousVacationMaster.travelDays = 0
        this.previousVacationMaster.vacationAndTravelDays = 0
        this.previousVacationMaster.carriedOverDays = 0
        this.previousVacationMaster.unScheduledVacationDays = 0
        this.previousVacationMaster.unscheduledRnRDays = 0
        this.previousVacationMaster.buyOutDays = 0
        this.previousVacationMaster.version = null
        this.showGenerateVacationButton = false
      },
      resetOtherData() {
        this.resetEmployeeData()
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.payrollCountryId = ''
        this.employeeInfo.payrollCountry = ''
        this.employeeInfo.isShiftEmployee = false
        this.employeeInfo.isResidentExpatriateEmployee = false
        this.resetVacationMasterData()
      },
      fetchEmployeeData(employee) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.request.vacationYear))
        tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.request.vacationYear - 1))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          var vacationMaster = results[1]
          var previousVacationMaster = results[2]
          if (!vacationMaster) {
            eventBus.$emit(Events.LongOperationCompleted)
            self.setData(employee, vacationMaster, previousVacationMaster)
            if (employee.isRegularEmployee && employee.workScheduleId == Enums.WorkSchedule.WS5x2.value) {
              self.showGenerateVacationButton = true
            }
            //if (employee.isRegularEmployee && employee.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
            //  this.generateCashInLieuRequest()
            //}
            //else {
            //  if (employee.isRegularEmployee && employee.workScheduleId == Enums.WorkSchedule.WS14x14.value) {
            //    self.showGenerateCashInLieuRequestButton = true
            //  }
            //  self.showGenerateVacationButton = true
            //}
          }
          else {
            self.showGenerateVacationButton = false
            self.setData(employee, vacationMaster, previousVacationMaster)
            self.setVacationData(vacationMaster)
            eventBus.$emit(Events.LongOperationCompleted)
          }
        }).catch((error) => {
          self.handleError(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      fetchExistingDataForView() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.PersonalBusinessDays.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          let tasks = []
          tasks.push(personalBusinessDaysRequestService.getNumberOfDaysTaken(employee.employeeId, new Date().getFullYear()))
          Promise.all(tasks).then((results) => {
            const numberOfDaysTaken = results[0]
            self.setData(employee, numberOfDaysTaken - request.duration, null, null, null, null, null, request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      setData(employee, vacationMaster, previousVacationMaster, request) {
        if (request) {
          this.setBaseRequestData(request)
        }
        else {
          this.request.intendedApproverId = employee.supervisorId
          this.request.intendedApproverName = employee.supervisorName
        }
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        this.setVacationData(vacationMaster, previousVacationMaster)
      },
      setVacationData(vacationMaster, previousVacationMaster) {
        if (vacationMaster) {
          this.vacationMaster.id = vacationMaster.id
          this.vacationMaster.vacationYear = vacationMaster.vacationYear
          this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
          this.vacationMaster.travelDays = vacationMaster.travelDays
          this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
          this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
          this.vacationMaster.unScheduledVacationDays = vacationMaster.unscheduledVacationDays
          this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
          this.vacationMaster.version = vacationMaster.version

          if (vacationMaster.carryOverRequests && vacationMaster.carryOverRequests.length) {
            for (const carryOverRequest of vacationMaster.carryOverRequests.filter(carryOverRequest => !carryOverRequest.isCancelled)) {
              this.vacationMaster.carryOverRequests.push(carryOverRequest)
            }
          }
        }
        if (previousVacationMaster) {
          this.previousVacationMaster.id = previousVacationMaster.id
          this.previousVacationMaster.vacationYear = previousVacationMaster.vacationYear
          this.previousVacationMaster.vacationDaysDue = previousVacationMaster.vacationDaysDue
          this.previousVacationMaster.travelDays = previousVacationMaster.travelDays
          this.previousVacationMaster.vacationAndTravelDays = previousVacationMaster.vacationAndTravelDays
          this.previousVacationMaster.carriedOverDays = previousVacationMaster.carriedOverDays
          this.previousVacationMaster.unScheduledVacationDays = previousVacationMaster.unscheduledVacationDays
          this.previousVacationMaster.unscheduledRnRDays = previousVacationMaster.unscheduledRnRDays
          this.previousVacationMaster.version = previousVacationMaster.version
        }
      },
      submitRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            let data = { ...self.request }
            if (!self.request.id) {
              data.employeeId = self.employeeInfo.employeeId
            }
            else {
              data.comment = self.comment
            }
            console.log(data)
            //let operation = self.request.id ? SAVE_EDITED_LEAVE_REQUEST : SCHEDULE_LEAVE_REQUEST
            leaveRequestService.schedule(data, self.employeeInfo.employeeId)
              .then(() => {
                self.requestState = Enums.RequestState.Submitted
                self.showSuccessMessage('request submitted successfully')
                self.isCompleted = true
                self.resetData()
              })
              .catch((error) => {
                console.log(error.appResultCode)
                if (error instanceof ELeaveError) {
                  console.log(error.appResultCode)
                  self.showErrorMessage(ErrorMessages.getErrorMessageByErrorCode(error.appResultCode))
                }
                else {
                  self.showErrorMessage(error)
                }
              }).finally(() => {
                self.isSavingRequest = false
              })
          }

        })
      },
      async validateRequest() {
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const requestForm = document.getElementById('entryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        else {
          const approverDirectReports = await employeeService.getEmployeeDirectReports(this.request.intendedApproverId)
          if (approverDirectReports && approverDirectReports.length) {
            return true
          }
          else {
            this.showErrorMessage('invalid approver. approver is not a supervisor')
            return false
          }
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(next(vm => {
        //vm.resetData()
        if (vm.$route.params.id) {
          vm.fetchExistingDataForView()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser)
        }
      })).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }).finally(() => {
      })
    },
    mounted() {
      const currentYear = new Date().getFullYear()
      this.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearCarryOverStartMonth - 1) {
        this.additionalData.vacationYears.push(currentYear + 1)
      }
      this.request.vacationYear = currentYear
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
