<template>
  <div>
    <div>
      <div class="form-group row mt-4">
        <label class="col-md-5">{{ vacationMaster.vacationYear }}&nbsp; vacation days due</label>
        <span class="col-md-6">{{ vacationMaster.vacationDaysDue }}</span>
      </div>
      <div class="row form-group mt-4">
        <label class="col-md-5">{{ vacationMaster.vacationYear }}&nbsp; unscheduled vacation days</label>
        <span class="col-md-6">{{ vacationMaster.unScheduledVacationDays }}</span>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-5"><span class="font-weight-bold text-danger">*</span>no. of days to carry over</label>
        <input type="number" class="form-control col-md-1" v-model="request.numberOfDays" required min="1" :max="maximumNumberOfDays" />
        <span v-if="request.numberOfDays > maximumNumberOfDays" class="text-danger font-weight-bold">{{ '( max: ' + maximumNumberOfDays + ' )'  }}</span>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-5"><span class="font-weight-bold text-danger">*</span>comment / reason</label>
        <textarea rows="3" class="form-control col-md-6" required v-model="request.reasonForChange"></textarea>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }

  .slide-fade-enter-active {
    transition: all .1s ease;
  }

  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  table tr th, table tr td {
    font-size: 12px !important;
  }
</style>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'vacation-carry-over-entry',
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      vacationMaster: {
        type: Object,
        required: true
      },
      additionalData: {
        type: Object,
        required: true
      },
    },
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      maximumNumberOfDays() {
        if (this.employeeInfo.isExpatriateEmployee) {
          return this.vacationMaster.unScheduledVacationDays
        }
        else {
          return Math.min(this.vacationMaster.unScheduledVacationDays, Math.ceil(this.vacationMaster.vacationDaysDue * this.appSettings.vacation.maxCarryOverPercentage))
        }
      }
    },
  }
</script>
